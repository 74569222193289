// 考试
<template>
  <div class="exam">
    <div class="exam_box margin-auto w-1200">
      <div class="exam_tag flexs">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>考试</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="exam_head flex p-l-20 p-r-20">
        <div class="exam_head_name">2020年终绩效考试</div>
        <div class="exam_head_time flexs">
          <span>剩余时间：</span>
          <span>58分34秒</span>
        </div>
      </div>
      <div class="exam_main m-t-20 flex">
        <div class="exam_main_left b-c-f">
          <div class="exam_main_head flexs">
            <span>选择题</span>
          </div>
          <ul class="exam_main_list p-b-40 p-l-20 p-r-20">
            <li v-for="(item,index) in topicList" :key="index">
              <div class="exam_main_list_name">{{ index + 1 }}、{{ item.ti }}（）</div>
              <div class="exam_main_list_ul">
                <div class="exam_main_list_li flexs" v-for="(em,ind) in item.topic" :key="ind">
                  <div class="exam_main_list_checked center m-r-20" :class="{active:getChoice(item,getA(ind)) || getAnswer(item,getA(ind)),red:item.answer.indexOf(item.cuo_answer) == -1 && item.cuo_answer == getA(ind)}">{{ getChoice(item,getA(ind)) || item.answer.indexOf(item.cuo_answer) == -1 && item.cuo_answer == getA(ind) || item.answer == getA(ind) || getAnswer(item,getA(ind)) ? '' : getA(ind) }}</div>
                  <div class="exam_main_list_txt">{{ em }}</div>
                </div>
              </div>
              <div class="exam_main_list_bot m-t-30">
                <div class="exam_main_list_bot_name flexs p-l-20">
                  <span>正确答案： </span>
                  <span>{{ item.answer }}</span>
                </div>
                <div class="exam_main_list_bot_txt p-b-20 p-t-20 p-l-20 p-r-20">
                  <span>答案解析： </span>
                  <span>{{ item.answer_text }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="exam_main_right b-c-f p-b-40">
          <div class="exam_main_head flexs">
            <span>答题卡</span>
          </div>
          <ul class="exam_main_right_ul flexs flex-wrap">
            <li class="center" :class="{active:item.answer_status == 1,red:item.answer_status == 0}" v-for="(item,index) in topicList" :key="index">{{ index + 1 }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
      topicList:[],//题目列表
      from:{
        id:null,//
        page:1,
        pageNum:1000
      }
    }
  },
  methods:{
    getChoice(item,txt) {
      if (item.answer.indexOf(item.cuo_answer) != -1 && item.cuo_answer == txt || item.answer == txt) return true
      else return false
    },
    getAnswer (item,txt) {
      if (item.answer.split(',').length >= 2) {
        if(!item.cuo_answer.split(',').includes(txt)) return ''
        else if(item.answer.split(',').includes(txt)) return true
        else return false
      }
    },
    //获取考试列表
    getkaoShiAnalysis () {
      this.$axios.kaoShiAnalysis(this.from).then(res=>{
        res.data.data.forEach(element => {
          element.topic = []
          element.answers.forEach(em=>{
            if (em) {
              element.topic.push(em)
            }
          })
        });
        this.topicList = res.data.data
      })
    },
    //获取类型
    getA (index) {
      // eslint-disable-next-line no-unused-vars
      let txt = ''
      switch (index) {
        case 0:
          txt = 'A'
          break;
        case 1:
          txt = 'B'
          break;
        case 2:
          txt = 'C'
          break;
        case 3:
          txt = 'D'
          break;
        case 4:
          txt = 'E'
          break;
        case 5:
          txt = 'F'
          break;
        default:
          break;
      }
      return txt
    }
  },
  created () {
    this.from.id = this.$route.query.id
    this.getkaoShiAnalysis()
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-breadcrumb__inner {
  color: #3273F6 !important;
}
/deep/ .is-link {
  color: #666666 !important;
}
.exam_tag {
  height: 52px;
}
.exam_head {
  height: 88px;
  background: #FFFFFF;
  border-top: 6px solid #82ABFE;
  .exam_head_name {
    color: #333333;
    font-size: 22px;
     font-weight: bold;
  }
  .exam_head_time {
    span {
      color: #333333;
      font-size: 18px;
      &:last-child {
        color: #FF2E2E;
        font-size: 24px;
      }
    }
  }
}
.exam_main_head {
  height: 64px;
  padding-left: 34px;
  border-bottom: 1px dashed #CCCCCC;
  span {
    position: relative;
    &::after {
      content: '';
      left: -14px;
      width: 4px;
      height: 24px;
      background: #3273F6;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.exam_main {
  align-items: flex-start;
}
.exam_main_left {
  width: 820px;
  border-radius: 4px;
}
.exam_main_right {
  width: 360px;
  border-radius: 4px;
}
.exam_main_list {
  li {
    margin-top: 30px;
    .exam_main_list_name {
      color: #333333;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .exam_main_list_ul {
      .exam_main_list_li {
        cursor: pointer;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .exam_main_list_checked {
          width: 32px;
          height: 32px;
          color: #333333;
          font-size: 20px;
          background: #F3F7FF;
          border-radius: 50%;
        }
        .active {
          background: url('../../assets/image/microlecture/correct.png') no-repeat;
          background-size: cover;
        }
        .red {
          background: url('../../assets/image/microlecture/mistake.png') no-repeat;
          background-size: cover;
        }
      }
    }
  }
}
.exam_main_right_ul {
  padding: 30px 34px 0px 34px;
  li {
    color: #666666;
    width: 32px;
    height: 32px;
    font-size: 18px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 50%;
    margin-right: 20px;
    margin-bottom: 30px;
    &:nth-child(6n) {
      margin-right: 0;
    }
  }
  .active,.red {
    color: #FFFFFF;
    border: none;
  }
  .active {
    
    background: #82ABFE;
   
  }
  .red {
    background: #FF2222;
  }
}
.exam_main_list_bot {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  .exam_main_list_bot_name {
    background: #F5F5F5;
    height: 44px;
    span {
      font-size: 16px;
      &:first-child {
        color: #666666;
      }
    }
  }
  .exam_main_list_bot_txt {
    line-height: 24px;
    span {
      font-size: 16px;
      &:first-child {
        color: #666666;
      }
    }
  }
}
</style>